<template>
  <div
    class="cx-recruitment"
    :class="is_mobile ? 'm-cx-recruitment' : 'pc-cx-recruitment'"
    @click="hidePannel"
  >
    <div class="cx-rec-banner">
      <img
        class="cx-rb-img"
        :src="is_mobile ? banner[recuritment_type].bg_m : banner[recuritment_type].bg"
      />
      <div class="cx-rb-title" v-if="recuritment_type=='social'">
        {{ $t("footer.social_recruitment") }}
      </div>
      <div class="cx-rb-title" v-if="recuritment_type=='campus'">
        {{ $t("footer.campus_recruitement") }}
      </div>
      <div class="cx-rb-title" v-if="recuritment_type=='internal'">
        {{ $t("footer.internal_recruitement") }}
      </div>
    </div>
    <div class="rec-type-bar">
      <div class="rec-tb-txt" v-if="!is_mobile">
        <span class="rec-tb-count">{{total}}</span>{{ $t("recuritment.count_txt") }}
      </div>
      <div class="rec-search-box">
        <input
          class="rec-search-in"
          type="txt"
          v-model="title"
          @keydown.enter="search"
          :placeholder="$t('recuritment.rec_search_placehold')"
        />
        <img class="rec-search-icon" :src="search_icon" @click.stop="search" />
      </div>
      <img class="rec-search-icon" v-if="is_mobile" :src="search_icon" @click.stop="search" />

      <div class="rec-picker-box" @click.stop="toggleAddressPannel">
        {{ $t("recuritment.rec_search_address") }}
      </div>
      <div class="rec-picker-box" @click.stop="togglePositionPannel">
        {{ $t("recuritment.rec_search_kind") }}
      </div>
      <div class="rec-rest-btn" v-if="!is_mobile" @click.stop="resetFilter">
        <img class="rec-rest-icon" :src="recuritment_rest_icon" />
        {{ $t("recuritment.reset_condi") }}
      </div>
      <div class="rec-type-panner" @click.stop="()=>false" v-if="show_address_panner">
          <happy-scroll hide-vertical hide-horizontal>
            <div class="rec-posi-cl rec-posi-cl-full">
              <div
                class="rec-position-item"
                :class="item.id == province_id ? 'rec-type-item-checked' : ''"
                v-for="(item, i) in district_list"
                @click.stop="getRecuritmentLisByDistruct(item.id)"
                :key="i"
              >
                {{
                  $t("language.shot_name") == "cn"
                    ? item.district_name
                    : item.en_district_name.toUpperCase()
                }}
              </div>
            </div>
          </happy-scroll>
      </div>
      <div class="rec-type-panner" @click.stop="()=>false" v-if="show_position_panner">
        <div class="rec-type-left">
          <happy-scroll hide-vertical hide-horizontal>
            <div
              class="rec-type-item"
              :class="
                item.id == parent_position_type ? 'rec-type-item-checked' : ''
              "
              @click.stop="getSubList(item)"
              v-for="(item, i) in category_list"
              :key="i"
            >
              {{ item[$t("language.shot_name") + "_type"] }}
            </div>
          </happy-scroll>
        </div>
        <div class="rec-type-right">
          <happy-scroll right hide-vertical hide-horizontal>
            <div class="rec-posi-cl">
              <div
                class="rec-position-item"
                :class="item.id == position_type ? 'rec-type-item-checked' : ''"
                v-for="(item, i) in sub_category_list"
                @click.stop="getRecuritmentListByCate(item.id)"
                :key="i"
              >
                {{ item[$t("language.shot_name") + "_order"] }}
              </div>
            </div>
          </happy-scroll>
        </div>
      </div>
    </div>
    <div class="rec-recuritment-wrap">
      <img class="rec-rw-bg" :src="is_mobile ? recuritment_bg_m : recuritment_bg" />
      <div class="rec-rec-data">
        <div class="rec-row" v-if="!is_mobile">
          <div class="rec-head">
            <div class="rec-col">{{ $t("recuritment.table.position_name") }}</div>
            <div class="rec-col">{{ $t("recuritment.table.position_type") }}</div>
            <div class="rec-col">{{ $t("recuritment.table.department") }}</div>
            <div class="rec-col">{{ $t("recuritment.table.need_number") }}</div>
            <div class="rec-col">{{ $t("recuritment.table.address") }}</div>
            <div class="rec-col">{{ $t("recuritment.table.create_time") }}</div>
          </div>
        </div>
        <div class="rec-row" v-for="(item, index) in recurit_list" :key="index">
          <div class="rec-list">
            <router-link :to="'/recruitment/detail?id=' + item.id">
              <div class="rec-col rec-col-positon">
                {{ item[$t("language.shot_name") + "_title"] }}
              </div>
              <div class="rec-col rec-col-3d">
                {{ item[$t("language.shot_name") + "_category"] }}
              </div>
              <div class="rec-col rec-col-3d">
                {{ item[$t("language.shot_name") + "_department"] }}
              </div>
              <div class="rec-col rec-col-3d">
                {{ item[$t("language.shot_name") + "_recruit_number"] }}
              </div>
              <div class="rec-col rec-col-2d">{{ $t("language.shot_name")=='cn' ? item.district_name:item.en_district_name.toUpperCase() }}</div>
              <div class="rec-col rec-col-2d">{{ item.input_time_date }}</div>
            </router-link>
          </div>
        </div>
        <div class="rec-pagination-box" v-if="!is_mobile && last_page > 1">
          <div class="rec-pagination-btn" v-if="current_page != 1" @click="prePage">&lt;</div>
          <div
            class="rec-pagination-btn"
            :class="current_page == index ? 'rec-pagination-btn-a' : ''"
            v-for="index in last_page"
            :key="index"
            @click="jump(index)"
          >
            {{ index }}
          </div>
          <div class="rec-pagination-btn" v-if="current_page < last_page" @click="nextPage">&gt;</div>
          <div class="rec-pagination-txt">{{ $t("recuritment.pagination.jump_to") }}</div>
          <div class="rec-pagination-jump">
            <input class="rec-page-input" type="number" v-model="input_page" />
          </div>
          <div class="rec-pagination-txt">{{ $t("recuritment.pagination.page") }}</div>
          <div class="rec-pagination-jbtn" @click.stop="jumpTo">
            {{ $t("recuritment.pagination.certain") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Recruitment",
  props: {},
  data() {
    return {
      recuritment_type: "social",
      banner: {
        social: {
          bg: require("@/assets/recuritment/social-banner.png"),
          bg_m: require("@/assets/recuritment/social-banner-m.png"),
        },
        campus: {
          bg: require("@/assets/recuritment/campus-banner.png"),
          bg_m: require("@/assets/recuritment/campus-banner-m.png"),
        },
        internal: {
          bg: require("@/assets/recuritment/internal-banner.png"),
          bg_m: require("@/assets/recuritment/internal-banner-m.png"),
        },
      },

      search_icon: require("@/assets/seach-icon.png"),
      recuritment_bg: require("@/assets/i-bg-a.png"),
      recuritment_bg_m: require("@/assets/series-bg.png"),

      recuritment_rest_icon: require("@/assets/del-icon.png"),

      show_address_panner: false,
      show_position_panner: false,

      province_id: 0,
      position_type: 0,
      parent_position_type:0,

      recurit_list: [],
      district_list: [],

      category_list: [],
      sub_category_list: [],

      title: "",

      current_page: 1,
      last_page: 0,
      input_page: "",

      work_place:'',

      total:''
    };
  },
  methods: {
    hidePannel() {
      this.show_address_panner = false;
      this.show_position_panner = false;
    },
    search() {
      this.current_page=1;
      this.recruitList();
    },
    togglePositionPannel() {
      this.show_address_panner = false;
      this.show_position_panner = !this.show_position_panner;
    },
    toggleAddressPannel() {
      this.show_position_panner = false;
      this.show_address_panner = !this.show_address_panner;
    },
    getRecuritmentLisByDistruct(id) {
      this.province_id = id;
      this.current_page=1;
      this.recruitList();
    },
    getRecuritmentListByCate(id) {
      this.position_type = id;
      this.current_page=1;
      this.recruitList();
    },
    getSubList(item) {
        this.parent_position_type=item.id
        if( item.id==0){
          this.position_type='';
          this.current_page=1;
          this.sub_category_list=[];
          this.recruitList();
        }else{
          this.sub_category_list = item.child
        }
    },
    prePage(){
      this.current_page--;
      this.recruitList();
    },
    nextPage(){
      this.current_page++;
      this.recruitList();
    },
    jumpTo() {
      if (parseInt(this.input_page) >= 1) {
        this.current_page = this.input_page;
        this.recruitList();
      }
    },
    jump(index){
      this.current_page = index;
      this.recruitList();
    },
    resetFilter(){
      this.position_type=''
      this.province_id=''
      this.title=''
      this.sub_category_list=[];
      this.parent_position_type=0;
      this.recruitList();
    },
    recruitList() {
      this.show_address_panner = false;
      this.show_position_panner = false;
      let params={
        position_id:0
      }
      if (this.recuritment_type == "social") {
        params.position_id = 0;
      } else if (this.recuritment_type == "campus") {
        params.position_id = 1;
      } else if (this.recuritment_type == "internal") {
        params.position_id = 2;
      }
      if(this.position_type){
        params.position_type=this.position_type
      }
      if(this.province_id){
        params.work_place=this.province_id
      }
      if(this.title){
        params.title=this.title
      }
      params.page=this.current_page;
      this.api
        .recruitList(params)
        .then((res) => {
          if (res.code == 200) {
            this.recurit_list = res.data.data;
            this.last_page = res.data.last_page;
            this.current_page = res.data.current_page;
            this.total=res.data.total;
            this.input_page = "";
          }
        });
    },
  },
  components: {},
  mounted() {
    this.recuritment_type = this.$route.params.type;
    this.recruitList();

    this.api.positionType().then((res) => {
      if (res.code == 200) {
        this.category_list = res.data;
        this.category_list.unshift({
          id: 0,
          cn_type: "全部",
          en_type: "All",
        })
      }
    });

    this.api.getWorkPlace().then((res) => {
      if (res.code == 200) {
        this.district_list = res.data;
        this.district_list.unshift({
          id: 0,
          district_name: "全部",
          en_district_name: "All",
        });
      }
    });

  },
};
</script>
<style lang="less">
.cx-recruitment {
  position: relative;
  overflow: hidden;
}
.pc-cx-recruitment {
  margin-top: 100px;
}
.cx-rec-banner {
  position: relative;
  height: 590px;
}
.cx-rb-img {
  position: absolute;
  width: 1920px;
  height: 590px;
  z-index: 0;
  left: 0px;
  top: 0px;
}
.cx-rb-title {
  position: absolute;
  top: 270px;
  right: 230px;
  font-size: 58px;
  color: #fff;
  z-index: 1;
}
.rec-type-bar {
  position: relative;
  height: 150px;
  line-height: 80px;
}

.rec-type-panner {
  position: absolute;
  width: 1000px;
  height: 420px;
  background-color: #636363;
  z-index: 111;
  top: 100px;
  left: 367px;
}

.rec-type-left {
  position: relative;
  height: 420px;
  width: 235px;
  float: left;
  border-right: #ededed solid 2px;
}
.rec-type-item {
  width: 205px;
  height: 45px;
  font-size: 20px;
  line-height: 45px;
  text-indent: 24px;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #fff;
}
.rec-type-item:hover{
  background-color: #3392ff;
  color: #fff;
}
.rec-type-item-checked {
  background-color: #3392ff;
  color: #fff;
}

.rec-type-right {
  height: 420px;
  width: 720px;
  float: left;
  text-align: left;
}
.rec-position-item {
  display: inline-block;
  height: 45px;
  font-size: 20px;
  line-height: 45px;
  border-radius: 5px;
  margin: 0px 0px 0px 20px;
  padding: 0px 20px;
  cursor: pointer;
  color: #fff;
}
.rec-position-item:hover {
  background-color: #3392ff;
  color: #fff;
}
.rec-tb-txt {
  float: left;
  color: #fff;
  font-size: 20px;
  margin-left: 80px;
  margin-top: 35px;
}
.rec-tb-count{
  font-size: 36px;
  font-weight: bold;
  margin-right: 10px;
}
.rec-search-box {
  position: relative;
  width: 320px;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px;
  border: #bdecff solid 1px;
  float: left;
  margin-top: 50px;
  margin-left: 85px;
}
.rec-search-in {
  position: relative;
  display: block;
  font-size: 20px;
  background: none;
  top: 10px;
  bottom: 10px;
  height: 30px;
  left: 10px;
  right: 80px;
  color: #fff;
}
.rec-search-in::placeholder {
  color: #fff;
}
.rec-search-icon {
  position: absolute;
  width: 27px;
  right: 10px;
  top: 10px;
  z-index: 11;
  cursor: pointer;
}
.rec-picker-box {
  position: relative;
  width: 180px;
  height: 50px;
  padding: 0px 20px;
  border: #bdecff solid 1px;
  float: left;
  margin-top: 50px;
  margin-left: 45px;
  font-size: 20px;
  line-height: 53px;
  text-align: left;
  color: #fff;
  cursor: pointer;
}
.rec-picker-box::after {
  position: absolute;
  content: "";
  display: block;
  border-top: 12px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  right: 20px;
  top: 18px;
}

.rec-recuritment-wrap {
  position: relative;
  min-height: 560px;
  z-index: 11;
  overflow: hidden;
}
.rec-rw-bg {
  position: absolute;
  left: 0px;
  width: 1920px;
  bottom: 0px;
  z-index: 0;
}
.rec-rest-btn {
  float: right;
  width: 200px;
  margin-right: 75px;
  margin-top: 40px;
  color: #777;
  font-size: 20px;
  cursor: pointer;
}
.rec-rest-icon {
  width: 24px;
  float: left;
  margin-right: 10px;
  margin-top: 25px;
}
.rec-rec-data {
  position: relative;
  width: 1740px;
  margin: 0 auto;
  z-index: 11;
  padding-bottom:40px;
}
.rec-col {
  width: 290px;
  float: left;
  font-size: 18px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 10px;
}
.rec-head {
  background: #1a1a1a;
  height: 100px;
  line-height: 100px;
  margin-top: 50px;
  margin-bottom: 20px;
}
.rec-list {
  height: 90px;
  line-height: 90px;
  border-bottom: #5e5e5e solid 1px;
}
.rec-pagination-box {
  overflow: hidden;
  margin-top: 50px;
}
.rec-pagination-btn {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  border: #fff solid 1px;
  margin: 0px 10px;
  cursor: pointer;
  overflow: hidden;
}
.rec-pagination-btn-a {
  background-color: #fff;
  color: #000;
}
.rec-pagination-txt {
  display: inline-block;
  color: #777;
  line-height: 40px;
  font-size: 20px;
  overflow: hidden;
}
.rec-pagination-jbtn {
  width: 60px;
  height: 40px;
  background-color: #fff;
  font-size: 20px;
  color: #000;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin: 0px 10px;
  overflow: hidden;
}
.rec-pagination-jump {
  width: 70px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
  border: #fff solid 1px;
  margin: 0px 10px;
  overflow: hidden;
}
.rec-page-input {
  margin-left: 5px;
}
.rec-pagination-jump input {
  color: #fff;
  font-size: 20px;
}

.rec-posi-cl {
  margin-top: 20px;
  text-align: left;
  width: 760px;
}
.rec-posi-cl-full{
  width: 1000px;
}
.m-cx-recruitment {
  .cx-rec-banner {
    height: 422px;
    .cx-rb-img {
      width: 750px;
      height: 422px;
    }
    .cx-rb-title {
      top: 180px;
      right: 30px;
      font-size: 41px;
    }
  }
  .rec-search-icon {
    width: 45px;
    top: 20px;
    right: 20px;
  }
  .rec-type-bar {
    height: 83px;
    .rec-search-box {
      width: 750px;
      margin: 0px;
      height: 83px;
      padding: 0px;

      .rec-search-in {
        font-size: 24px;
        height: 83px;
        top: 0px;
        bottom: 0px;
      }
    }
    .rec-picker-box {
      height: 83px;
      margin: 0px;
      font-size: 29px;
      width: 333px;
      line-height: 83px;
      border-top:none;
    }
    .rec-picker-box::after {
      border-top: #fff solid 25px;
      top: 30px;
      right: 20px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
    }
    .rec-type-panner {
      left: 0px;
      z-index: 111;
      top: 165px;
      height:480px;
      width: 750px;
      .rec-type-left {
        width: 324px;
        height:480px;
      }
      .rec-type-right {
        width: 380px;
        height:480px;
        .rec-position-item {
          font-size: 27px;
        }
      }
    }
  }
  .rec-recuritment-wrap {
    width: 750px;
    overflow: hidden;
    height: auto;
    .rec-rw-bg {
      width: 750px;
      bottom: 0px;
    }
    .rec-rec-data {
      width: 700px;
      margin: 40px auto;
      overflow: hidden;
      .rec-row {
        height: auto;
        .rec-list {
          height: auto;
          text-align: left;
          line-height: 50px;
          padding: 10px 0px;
          .rec-col {
            float: none;
          }
          .rec-col-positon {
            display: block;
            width: 700px;
            font-size: 25px;
          }
          .rec-col-3d {
            display: inline-block;
            width: auto;
            font-size: 18px;
          }
          .rec-col-2d {
            display: inline-block;
            width: auto;
            font-size: 18px;
          }
        }
      }
    }
  }
  .rec-posi-cl{
    width: 400px;
  }
  .rec-posi-cl-full{
    width: 750px;
  }

}
.rec-type-left .happy-scroll-container .happy-scroll-content{
  margin-left:-60px;
}
</style>
